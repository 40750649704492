import React from 'react'
import starIcon from '../../assets/images/fav-a.svg'
import starIcon2 from '../../assets/images/fav-b.svg'
import contact from '../../assets/images/contact.png'

const ContactForm = () => {
    return (
        <section className="contact-area pb-100">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="contact" /> 
                        Contáctanos
                    </span>
                    <h2>¿Quieres saber más? Escríbenos</h2>
                    <p>(Jamás compartiremos tus datos con terceros). Los campos marcados con * son obligatorios</p>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="contact-image">
                            <img src={contact} alt="contact" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="contact-form">
                            <form id="contactForm" action="https://getform.io/f/b29d040f-67e3-464b-b261-c47e06b07fe5" method="POST">
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                name="name" 
                                                className="form-control" 
                                                required 
                                                placeholder="Nombre *" 
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input 
                                                type="email" 
                                                name="email" 
                                                className="form-control" 
                                                required 
                                                placeholder="Correo electrónico *" 
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                name="phone_number" 
                                                className="form-control" 
                                                required 
                                                placeholder="Número telefónico" 
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea 
                                                name="message" 
                                                className="form-control" 
                                                cols="30" 
                                                rows="6" 
                                                required 
                                                placeholder="Tu mensaje... *" 
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <button type="submit" className="default-btn">
                                            <i className="flaticon-tick"></i> 
                                            Enviar <span></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactForm