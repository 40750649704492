import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ContactInfo from '../components/Contact/ContactInfo'
import ContactForm from '../components/Contact/ContactForm'
const Contact = () => {
    return (
        <Layout
            titulo="Contacto"
            descripcion="Contáctanos"
            slug="contact"
            imagen="https://res.cloudinary.com/dev-empty/image/upload/v1630907493/project_start1_fcd74cc421.png"
        >

            <Navbar />

            <PageBanner
                pageTitle="Contáctanos" 
                homePageText="Inicio" 
                homePageUrl="/" 
                activePageText="Contacto" 
            />
            
            <ContactInfo />

            <ContactForm />

            <Footer />
            
        </Layout>
    );
}

export default Contact